import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-digital-assistants",
  "title": "Vad är en digital assistent?",
  "text": "En digital assistent är en mjukvarurobot som kan utföra alla typer av arbetsuppgifter som vi människor gör. Den imiterar vårt arbetssätt, jobbar i samma system men betydligt effektivare.",
  "mascot": "/img/robbie.png",
  "buttonText": "Skapa din assistent",
  "buttonPath": "createassistant",
  "categories": [{
    "bg": "#fff",
    "caseTitle": "Case: Acne Studios",
    "textList": [{
      "text": "Många processer inom en ekonomiavdelning innehåller repetitiva och monotona arbetsmoment och lämpar sig därför väl för automatisering. Vanliga processer att automatisera är exempelvis kontoavstämningar, fakturering, hantering av inkommande fakturor och löneadministration."
    }],
    "checkpoints": [{
      "text": "Kontoavstämningar"
    }, {
      "text": "Fakturering"
    }, {
      "text": "Fakturahantering"
    }, {
      "text": "Leverantörsreskontra"
    }, {
      "text": "Kundreskontra"
    }, {
      "text": "Finansiell rapportering"
    }, {
      "text": "Bokföring"
    }],
    "text": "Många processer inom en ekonomiavdelning innehåller repetitiva och monotona arbetsmoment och lämpar sig därför väl för automatisering. Vanliga processer att automatisera är exempelvis kontoavstämningar, fakturering, hantering av inkommande fakturor och löneadministration.",
    "optionalButton": {
      "path": "-",
      "text": "-"
    },
    "caseText2": "Zimply har automatiserat arbetet för Acne Studios. En robot går igenom varje faktura och kontrollerar dem mot regler som finns uppsatta (rätt konto, valuta osv.). Ifall allt är korrekt så godkänner roboten fakturan som sedan går iväg till betalning. Om det inte är korrekt går fakturan tillbaka till respektive ansvarig avdelning som köpt in produkten eller tjänsten.",
    "title": "Ekonomi",
    "image": "/img/calc.png",
    "caseTextList": [{
      "text": "Avdelningen för Leverantörsreskontra får dagligen in fakturor från leverantörer som behöver kontrolleras och godkännas. Godkännandet görs av respektive avdelning som köpt in produkter eller tjänster. Medarbetare på leverantörsreskontra behöver sedan manuellt kontrollera och göra ett sista godkännande innan betalningen görs mot leverantören. Det är ett manuellt, monotont och tidskrävande steg."
    }, {
      "text": "Zimply har automatiserat arbetet för Acne Studios. En robot går igenom varje faktura och kontrollerar dem mot regler som finns uppsatta (rätt konto, valuta osv.). Ifall allt är korrekt så godkänner roboten fakturan som sedan går iväg till betalning. Om det inte är korrekt går fakturan tillbaka till respektive ansvarig avdelning som köpt in produkten eller tjänsten."
    }],
    "caseText": "Avdelningen för Leverantörsreskontra får dagligen in fakturor från leverantörer som behöver kontrolleras och godkännas. Godkännandet görs av respektive avdelning som köpt in produkter eller tjänster. Medarbetare på leverantörsreskontra behöver sedan manuellt kontrollera och göra ett sista godkännande innan betalningen görs mot leverantören. Det är ett manuellt, monotont och tidskrävande steg."
  }, {
    "title": "Back office",
    "textList": [{
      "text": "Växande regelverk gör att den administrativa bördan för back office ständigt växer och sällan hinner systemstöden växa i samma takt. Detta resulterar ofta i manuellt arbete med repetitiva och monotona arbetsuppgifter som tar upp värdefull tid för de anställda. Med manuellt mänskligt arbete följer dessutom en risk för fel. Samtidigt pågår en digitalisering och modernisering av många arbetsprocesser där gamla dokument och annan data behöver digitaliseras och matas in i nya system. Tack vare kort utvecklingstid och hög anpassningsbarhet är tillämpningsområdena för digitala assistenter många och affärsvärdet högt med en vanlig ROI på tre månader."
    }, {
      "text": "Samtidigt pågår en digitalisering och modernisering av många arbetsprocesser där gamla dokument och annan data behöver digitaliseras och matas in i nya system."
    }, {
      "text": "Tack vare kort utvecklingstid och hög anpassningsbarhet är tillämpningsområdena för digitala assistenter många och affärsvärdet högt med en vanlig ROI på tre månader."
    }],
    "caseTitle": "Case: Futur Pension",
    "caseTextList": [{
      "text": "Futur Pension hade ett stort antal ostrukturerade, varierande inskannade ansökningshandlingar som sträckte sig tillbaka flera år. Informationen från dessa behövde tolkas och läggas in i deras system. I detta fall rörde det sig om 22 000 inskannade dokument som sträckte sig över 3 år."
    }, {
      "text": "Zimply automatiserade avläsningen av dokumenten för Futur Pension. Genom att träna en digital assistent kunde Zimply lära sig tolka de olika ansökningshandlingarna och automatiskt bearbeta dokumenten. På så sätt kunde informationen från blanketterna transformeras till digitalt format. Zimply tränade sin digitala assistent och utförde uppgiften på 1 månad."
    }],
    "image": "/img/paper.png",
    "bg": "#f1f5ff",
    "checkpoints": [{
      "text": "Skaderegistrering"
    }, {
      "text": "Complianceprocesser"
    }, {
      "text": " Dualitetskontroller"
    }, {
      "text": "Öppna/Avsluta konton eller kort"
    }, {
      "text": "AML/KYC"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "HR",
    "textList": [{
      "text": "Employer branding är idag viktigare än någonsin med en mer rörlig arbetskraft och hårdare konkurrens om talangerna. Genom att automatisera de administrativa delarna av HR-processer frigörs tid så er personal kan fokusera på de värdeskapande delarna av HR för att göra företaget till en attraktiv arbetsplats med välmående medarbetare. Låt Zimply hjälpa er att skapa förutsättningar för er HR-avdelning genom att automatisera och frigöra dem från hela eller delar av administrativa processer"
    }],
    "caseTitle": "Case: (konsultbyrå)",
    "caseTextList": [{
      "text": "Zimply hjälpte konsultbyrån att effektivisera sin HR-avdelnings manuella onboardingprocess genom att ge en digitala assistent en egen intern e-postadress dit företagets HR-personal skickar färdiga anställningsavtal. När assistenten tar emot ett mail öppnar den dokumentet och läser av kontaktuppgifter och anställningsinformation från anställningsavtalet. Därefter öppnar den det interna HR-systemet och skapar en användare/anställd och fyller i den hämtade informationen från anställningsavtalet och slutligen skickas ett välkomstbrev till den anställde."
    }],
    "image": "/img/thinking.png",
    "bg": "#c6d8e7",
    "checkpoints": [{
      "text": "CV-screening"
    }, {
      "text": "On- och offboarding av anställda"
    }, {
      "text": " Administration av utlägg"
    }, {
      "text": "Tidrapportering och uppföljning"
    }, {
      "text": "Frånvarorapportering"
    }, {
      "text": "Löneadministration"
    }, {
      "text": "Referenstagning"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "IT",
    "textList": [{
      "text": "Flertalet av arbetsuppgifterna inom IT-support utförs dagligen och innefattar repetitiva steg som sträcker sig över många olika system. Hanteringstiden behöver även vara minimal för att åtgärden ska träda i kraft så fort det går. Våra digitala assistenter kan hjälpa er med att exempelvis hantera systembehörigheter, migrera data, hantera infrastruktur inom en mängd olika miljöer (Azure, AWS, Google Cloud, VMWare, Citrix med flera) och testautomatisering. Låt er IT-avdelning fokusera på er digitala resa och vidareutveckla era system samtidigt som ni får en effektivare IT-administration med kortare ledtider. Det kommer uppskattas av såväl er IT-avdelning som era användare och i slutändan era kunder."
    }],
    "caseTitle": "Case: Försäkringsbolag",
    "caseTextList": [{
      "text": "Vår digitala assistent automatiserar tester åt IT-avdelningen hos ett stort försäkringsbolag. Kunden har löpande releaser av systemuppdateringar som behöver testas. Tidigare gjordes detta manuellt av personal från IT-avdelningen och verksamheten. Det var mycket tidskrävande och ett repetitivt arbete som krävde stor noggrannhet. Zimplys digitala assistenter genomför testautomation för regressionstester, systemtester och acceptanstester. De arbetar i flera olika system beroende på vad som behöver testas. De digitala assistenterna minimerar risken för mänskliga fel, utför testerna snabbare och till en bråkdel av kostnaden."
    }],
    "image": "/img/laptop.png",
    "bg": "#ffffff",
    "checkpoints": [{
      "text": "Administration av behörigheter"
    }, {
      "text": "Migration av data till molnet"
    }, {
      "text": "On- och offboarding av anställdas behörigheter i system"
    }, {
      "text": "Infrastrukturhantering (administration av exempelvis Azure-, AWS-, VMWare-, samt Citrixmiljöer)"
    }, {
      "text": "Filhantering"
    }, {
      "text": "Testautomatisering"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "Inköp/Retail",
    "textList": [{
      "text": "Löpande orderhantering och leveransplanering är tillsammans med kontraktshantering och avtalsregistreringar stora tidstjuvar som tar mycket tid. Dessa monotona moment passar utmärkt till att automatisera med hjälp av en digital assistent."
    }],
    "caseTitle": "Case: Acne Studios",
    "caseTextList": [{
      "text": "Inköpsavdelningen på Acne Studios uppdaterar löpande det estimerade leveransdatumet på inköpsorder. Medarbetarna uppdaterar datumet i källsystemet baserat på en Excel fil som skickas till enheten varje månad. Varje fil kan innehålla flera hundra rader med olika artiklar som ska uppdateras manuellt på samma sätt."
    }, {
      "text": "Zimply automatiserade Acne Studios veckouppdatering av inköpsordrar med hjälp av en digital assistent som läser av samtliga Excelfiler och därefter automatiskt uppdaterar datumet i beställning."
    }],
    "image": "/img/order.png",
    "bg": "#f7ffec",
    "checkpoints": [{
      "text": "Orderhantering / orderregistrering"
    }, {
      "text": "Kontraktshantering"
    }, {
      "text": " Leveransplanering"
    }, {
      "text": "Avtalsregistrering"
    }, {
      "text": "Skapa inköpsorder"
    }, {
      "text": "Butiksavstämningar"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "Övrigt",
    "textList": [{
      "text": "Varje företag är unikt och har sina egna varianter på processer och arbetsuppgifter. Det finns också arbetsuppgifter som löper över flera avdelningar, som när en säljare registrerar en ny kund och den administrativa funktionen sedan tar över och slutför registreringen."
    }],
    "caseTitle": "-",
    "caseTextList": [{
      "text": "Zimply automatiserar alla typer av processer för företag inom alla branscher. Boka en demo för att ta reda på hur vi hjälper just dig och ditt företag."
    }],
    "image": "/img/glass.png",
    "bg": "#f1f5fe",
    "checkpoints": [],
    "optionalButton": {
      "text": "Skapa din assistent",
      "path": "createassistant"
    }
  }],
  "bottom": {
    "bg": "/img/pink.png",
    "title": "Kom igång!",
    "text": "När processerna har valts ut börjar förändringsarbetet. Följ med in i en värld av nya tekniska möjligheter, skräddarsydda efter era behov. Kontakta oss här nedanför!",
    "buttonText": "Kontakta oss",
    "buttonPath": "contact"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      